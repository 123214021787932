import { memo, useEffect, useState } from "react";
import { IconButton, Text } from "@components";
import { areEqualObject } from "@utils";
import { useDeliveryAddressGetById } from "@api";

const Default = memo((props) => {
  const [callbackGet] = useDeliveryAddressGetById();
  const [deliveryData, setDeliveryData] = useState({});

  const { id, description, status, cancelReason, expanded } = props;
  const isClientInfoAvailable = status !== "open" && status !== "cancelled";

  const username = props.client ? props.client.username : undefined;
  const first = props.client ? props.client.first : undefined;
  const last = props.client ? props.client.last : undefined;

  const caption = `Заказ от клиента: ` + ((first || last) ? `${first ?? ""} ${last ?? ""}` : `Клиент не найден`);
  const cancelReasonString = `Причина отмены заказа: ${cancelReason ?? "Информация не найдена"}`;
  const comment = description ? `Комментарий: ${description}` : null;

  const address = `Адрес: ${deliveryData.address ?? "Информация не найдена"}`;
  const country = `Страна: ${deliveryData.country ?? "Информация не найдена"}`;
  const city = `Город: ${deliveryData.city ?? "Информация не найдена"}`;
  const postcode = `Почтовый индекс: ${deliveryData.postcode ?? "Информация не найдена"}`;

  const name = `Имя: ${deliveryData.name ?? "Информация не найдена"}`;
  const phone = `Номер телефона: ${deliveryData.phone ?? "Информация не найдена"}`;
  const email = `Электронная почта: ${deliveryData.email ?? "Информация не найдена"}`;

  useEffect(() => {
    if (expanded) {
      console.log(expanded, id);
      return () => {
        console.log("unMount", id);
      };
    }
  }, [expanded, id]);

  useEffect(() => {
    if (isClientInfoAvailable === true) {
      callbackGet(id, setDeliveryData);
    }
  }, [id, callbackGet]);

  const copyTgAddress = () => {
    navigator.clipboard.writeText(`https://t.me/${username}`);
  };

  return (
    <>
      <Text caption={caption} />
      <div style={{ display: "flex" }}>
        <Text
          caption={
            <div style={{ marginTop: "8px" }}>
              Ссылка на чат:
              {username ? (
                <a style={{ marginLeft: "8px" }} href={`https://t.me/${username}`}>
                  {`https://t.me/${username}`}
                </a>
              ) : (
                " Нет юзернейма клиента"
              )}
            </div>
          }
        />
        {username && <IconButton textIcon="content_copy" color="blue" onClick={copyTgAddress} />}
      </div>
      {cancelReason && <Text style={{ marginTop: "16px" }} caption={cancelReasonString} />}
      {comment && <Text style={{ marginTop: "16px" }} caption={comment} />}
      {isClientInfoAvailable && deliveryData.id && (
        <div style={{ marginTop: "16px" }}>
          <Text caption={address} />
          <Text caption={country} />
          <Text caption={city} />
          <Text caption={postcode} />

          <Text style={{ marginTop: "16px" }} caption={name} />
          <Text caption={phone} />
          <Text caption={email} />
        </div>
      )}
    </>
  );
}, areEqualObject);

export default Default;
