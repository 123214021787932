/* eslint-disable no-template-curly-in-string */
import { Box, Input, Autocomplete, Container } from "@components";
import { isFunc, checkInput, compressImage } from "@utils";
import { useCallback, useState, useEffect } from "react";
import { DialogContent, TextField } from "@mui/material";
import Actions from "./actions";
import { useArticleGetAll as useGetAll, useGoodGetById2 as UseGetById, useImagesGetWithStatus } from "@api";

export default (props) => {
  const { onClose, onSave, id } = props;

  const [data, setData] = useState({});
  const [save, setSave] = useState(false);
  const [error, setError] = useState({});
  const [callbackGet, loading] = UseGetById();

  const [isFinishedLoadImage, setFinishedLoadImage] = useState(false);
  const [isUpdateImage, setIsUpdateImage] = useState(false);
  const [imagePlaceholder, setImagePlaceholder] = useState(null);
  const [imagePlaceholderStatus, setImagePlaceholderStatus] = useState(404);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (id) {
      callbackGet(id, setData);
    }
  }, [id, callbackGet]);

  const validate = useCallback((data) => {
    return checkInput(data, [
      {
        name: "caption",
        maxLength: {
          val: 200,
          errorMessage: "Заголовок не должен быть длиннее ${val} символов"
        },
        minLength: {
          val: 3,
          errorMessage: "Заголовок не должен быть короче ${val} символов"
        }
      },
      {
        name: "articleId",
        isNotNull: { errorMessage: "Должен быть выбранный" }
      },
      {
        name: "price.purchase",
        isNotNull: { errorMessage: "Поле не может быть пустым" },
        maxValue: {
          val: 9999,
          errorMessage: "Сумма не должна быть больше ${val}"
        },
        minValue: {
          val: 0,
          errorMessage: "Сумма не должна быть меньше ${val}"
        },
        isNumeric: { errorMessage: "Цена должна быть числом" }
      },
      {
        name: "warehouse.availability",
        isNotNull: { errorMessage: "Поле не может быть пустым" },
        maxValue: {
          val: "9999",
          errorMessage: "Количество не должно быть больше ${val}"
        },
        minValue: {
          val: "0",
          errorMessage: "Количество не должно быть меньше ${val}"
        },
        isInteger: { errorMessage: "Количество товара должно быть целым числом" }
      },
      {
        name: "weight",
        isNotNull: { errorMessage: "Поле не может быть пустым" },
        maxValue: {
          val: 9999,
          errorMessage: "Вес не должен быть больше ${val} грамм"
        },
        minValue: {
          val: 0,
          errorMessage: "Вес не должен быть меньше ${val} грамм"
        }
      }
    ]);
  }, []);

  const handleOnSave = useCallback(() => {
    setSave((prev) => !prev);
  }, []);

  useEffect(() => {
    if (save) {
      isFunc(onSave, { ...data, articleId: data?.articleId?.id, id, image });
    }
    if (data.personalImage) {
      loadExistedImage(data.personalImage);
    }
  }, [onSave, data, save]);

  useEffect(() => {
    setError(validate(data));
  }, [data, validate]);

  const handleChange = useCallback((param) => {
    return (event) => {
      setData((prev) => {
        if (param === "price.purchase") {
          if (prev.price) {
            prev.price.purchase = event.target.value;
          } else {
            prev.price = { purchase: event.target.value };
          }
        } else if (param === "warehouse.availability") {
          if (prev.warehouse) {
            prev.warehouse.availability = event.target.value;
          } else {
            prev.warehouse = { availability: event.target.value };
          }
        } else {
          prev[param] = event.target.value;
        }
        return { ...prev };
      });
    };
  }, []);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.log("Файл не выбран.");
      return;
    }

    if (!file.type.startsWith("image/")) {
      console.log("Выбранный файл не является изображением.");
      return;
    }

    try {
      setFinishedLoadImage(false);
      setIsUpdateImage(true);
      const compressedBlob = await compressImage(file);
      const compressedFile = new File([compressedBlob], file.name, { type: file.type });
      setImage(compressedFile);
      setFinishedLoadImage(true);
      console.log(
        "Изображение успешно сжато. Картинка весила: " +
          file.size / 1000 +
          " Кб, сжата до: " +
          compressedBlob.size / 1000 +
          " Кб"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const loadExistedImage = async (imageName) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { url, status } = await useImagesGetWithStatus(imageName);
    setImagePlaceholderStatus(status);
    if (status === 200) {
      setImagePlaceholder(url);
    }
  };

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            padding: (theme) => theme.spacing(1, 0),
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%"
          }}
        >
          <Container error={error} data={data} onChange={handleChange} loading={loading}>
            <Input name="caption" caption="Заголовок" />
            <Input isMultiline={true} name="description" caption="Описание" />
            {/* TODO: - show image */}
            <div style={{ border: "1px solid gray", borderRadius: "5px", padding: "16px", backgroundColor: "#f0f0f8" }}>
              <p style={{ margin: "-4px 0 8px 0px" }}>
                Изображение товара:{" "}
                {(data?.personalImage ? data?.personalImage : "Не выбрано") +
                  (imagePlaceholderStatus !== 200 && data?.personalImage ? " (Файл изображения не найден)" : "")}
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                {imagePlaceholder ? (
                  <img style={{ width: "100px", height: "100px" }} src={imagePlaceholder} alt="image" />
                ) : (
                  false
                )}
                <TextField
                  style={{
                    width: "100%",
                    marginLeft: imagePlaceholderStatus !== 200 || !imagePlaceholder ? "0px" : "16px"
                  }}
                  type="file"
                  onChange={handleImageChange}
                  inputProps={{ accept: "image/*" }}
                />
              </div>
            </div>
            <Input name="teaCategory" caption="Категория чая" />
            <Input name="price.purchase" caption="Цена в долларах за грамм" />
            <Input name="weight" caption="Вес в граммах" />
            <Input name="warehouse.availability" caption="Количество товара в наличии" />
            <Autocomplete name="articleId" caption="Артикул" useGet={useGetAll} />
          </Container>
        </Box>
      </DialogContent>
      <Actions
        handleOnSave={handleOnSave}
        disabled={error.isError || (isFinishedLoadImage === false && isUpdateImage === true)}
        onClose={onClose}
      />
    </>
  );
};
