import { memo, useEffect } from "react";
import { IconButton, Text } from "@components";
import { areEqualObject } from "@utils";

const Default = memo((props) => {
  const { id, username, expanded } = props;
  const first = props.first ? props.first : undefined;
  const last = props.client ? props.last : undefined;
  console.log(props);

  const caption = (first || last) ? `${first ?? ""} ${last ?? ""}` : `Клиент не найден`;

  useEffect(() => {
    if (expanded) {
      console.log(expanded, id);
      return () => {
        console.log("unMount", id);
      };
    }
  }, [expanded, id]);

  const copyTgAddress = () => {
    navigator.clipboard.writeText(`https://t.me/${username}`);
  };

  return (
    <>
      <Text caption={caption} />
      <div style={{ display: "flex" }}>
        <Text
          caption={
            <div style={{ marginTop: "8px" }}>
              Ссылка на чат:
              {username ? (
                <a style={{ marginLeft: "8px" }} href={`https://t.me/${username}`}>
                  {`https://t.me/${username}`}
                </a>
              ) : (
                " Нет юзернейма клиента"
              )}
            </div>
          }
        />
        {username && <IconButton textIcon="content_copy" color="blue" onClick={copyTgAddress} />}
      </div>
    </>
  );
}, areEqualObject);

export default Default;
