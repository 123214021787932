import { memo } from "react";
import { Text, IconButton, Box } from "@components";
import { areEqualObject } from "@utils";
import { useOrdersContext as useContext } from "@context";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line react/display-name
const Default = memo((props) => {
  const { id, status } = props;
  const first = props.client ? props.client.first : undefined;
  const last = props.client ? props.client.last : undefined;
  const isEditOrderInfoEnabled = status !== "cancelled";

  const caption = `#${id} ` + ((first || last) ? `${first ?? ""} ${last ?? ""}` : `Клиент не найден`);

  const { dialog } = useContext();
  const navigate = useNavigate();

  const handleOnViewOrder = (event) => {
    navigate(`/admin/orders/order/${id}`);
    event.stopPropagation();
  };

  const handleOnEdit = (event) => {
    if (isEditOrderInfoEnabled) {
      dialog.setIsShowEdit(true, { select: id });
    }
  };

  const handleOnDelete = (event) => {
    dialog.setIsShowDelete(true, {
      select: id,
      onGetText: () => caption
    });
    event.stopPropagation();
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: 1,
        alignItems: "center",
        padding: (them) => them.spacing(0, 1, 0, 1)
      }}
    >
      <Text sx={{ flexGrow: 1 }} caption={caption} />
      {status && (
        <Text
          onClick={() => {
            handleOnEdit();
          }}
          sx={{ flexShrink: 0 }}
          style={{
            color: "#1976d2",
            marginRight: "12px",
            border: "1px solid #1976d2",
            padding: "6px",
            borderRadius: "10px",
            opacity: isEditOrderInfoEnabled ? 1 : 0.5
          }}
          caption={"Status: " + status}
        />
      )}
      <IconButton textIcon="shopping_cart_checkout" color="primary" onClick={handleOnViewOrder} />
      <IconButton textIcon="delete" color="error" onClick={handleOnDelete} />
    </Box>
  );
}, areEqualObject);

export default Default;
